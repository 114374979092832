import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64f6a3f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "customer-summary-card-user-details__user-name" }
const _hoisted_2 = { class: "customer-summary-card-user-details__amount-app-data" }
const _hoisted_3 = { class: "app-data-inner" }
const _hoisted_4 = { class: "section-header__actions" }
const _hoisted_5 = { class: "customer-summary-card-user-details__app-id" }
const _hoisted_6 = {
  id: "appId",
  "data-test": "detAppId"
}
const _hoisted_7 = { class: "customer-summary-card-user-details__balance__label" }
const _hoisted_8 = {
  key: 1,
  class: "customer-summary-card-user-details__spent-amount"
}
const _hoisted_9 = { "data-test": "purchaseWindowEndLabel" }
const _hoisted_10 = {
  key: 1,
  class: "select-user-txt",
  "data-test": "nameLabel"
}
const _hoisted_11 = {
  key: 0,
  class: "tablet-section"
}
const _hoisted_12 = { class: "customer-summary-card-user-details__balance__label text-left" }
const _hoisted_13 = { class: "customer-summary-card-user-details__spent-amount text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_copy_to_clipboard_popup = _resolveComponent("copy-to-clipboard-popup")!
  const _component_customer_summary_details_user_details_modal = _resolveComponent("customer-summary-details-user-details-modal")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.wrapElementsTablet ? 'flex-wrap' : '', "customer-summary-card-user-details"])
    }, [
      (_ctx.applicationSelected)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_v_avatar, {
                class: "avatar text-uppercase",
                color: "var(--grayscale-color-4)",
                size: _ctx.avatarSize
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.nameInitials), 1)
                ]),
                _: 1
              }, 8, ["size"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass([_ctx.isSpecificWidth ? 'flex-wrap' : '', "d-flex justify-space-between"])
                }, [
                  _withDirectives((_openBlock(), _createBlock(_component_custom_button, {
                    variant: "text",
                    size: "small",
                    "data-test": "nameButton",
                    class: "btn-compact text-capitalize",
                    onClick: _ctx.showUserDetailsModal
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.name), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])), [
                    [_directive_private, 'Customer name on summary card']
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.customerAccount && _ctx.isAdminOrAbove)
                      ? (_openBlock(), _createBlock(_component_v_menu, {
                          key: 0,
                          "min-width": "7rem",
                          offset: "0 -16",
                          location: "left"
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_icon, _mergeProps({
                              class: "table-header__icon",
                              "data-test": "refundMenuBtn"
                            }, props), {
                              default: _withCtx(() => [
                                _createTextVNode(" mdi-dots-vertical ")
                              ]),
                              _: 2
                            }, 1040)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item, {
                                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('showRefundsDialog')))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item_title, {
                                      class: "menu-item",
                                      "data-test": "refundBtn"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Refund ")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ], 2),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.appId), 1),
                  _createVNode(_component_copy_to_clipboard_popup, {
                    "string-to-copy": _ctx.appId ? _ctx.appId.replaceAll('-', '') : ''
                  }, null, 8, ["string-to-copy"])
                ])
              ]),
              (_ctx.isValidForTransaction(_ctx.customerAccount) && _ctx.creditBalance && !_ctx.isTablet)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([{ 'customer-summary-card-user-details__balance--unknown': !_ctx.creditBalance }, "customer-summary-card-user-details__balance"])
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", null, [
                        _createTextVNode(" Available Spend: "),
                        _withDirectives((_openBlock(), _createElementBlock("span", null, [
                          _createTextVNode(_toDisplayString(_ctx.creditBalance), 1)
                        ])), [
                          [_directive_private]
                        ])
                      ])
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              (!_ctx.isTablet)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, [
                      _createTextVNode("Purchase Window End Date: "),
                      _withDirectives((_openBlock(), _createElementBlock("span", null, [
                        _createTextVNode(_toDisplayString(_ctx.purchaseWindowEndDate), 1)
                      ])), [
                        [_directive_private]
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_customer_summary_details_user_details_modal, {
              dialog: _ctx.showUserDetails,
              onClose: _ctx.closeUserDetailsModal
            }, null, 8, ["dialog", "onClose"])
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.name), 1))
    ], 2),
    (_ctx.applicationSelected && _ctx.isTablet)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (_ctx.isValidForTransaction(_ctx.customerAccount) && _ctx.creditBalance)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([{ 'customer-summary-card-user-details__balance--unknown': !_ctx.creditBalance }, "customer-summary-card-user-details__balance"])
              }, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("span", null, [
                    _createTextVNode(" Available Spend: "),
                    _withDirectives((_openBlock(), _createElementBlock("span", null, [
                      _createTextVNode(_toDisplayString(_ctx.creditBalance), 1)
                    ])), [
                      [_directive_private]
                    ])
                  ])
                ])
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("span", null, [
              _createTextVNode("Purchase Window End Date: "),
              _withDirectives((_openBlock(), _createElementBlock("span", null, [
                _createTextVNode(_toDisplayString(_ctx.purchaseWindowEndDate), 1)
              ])), [
                [_directive_private]
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}