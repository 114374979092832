<template>
  <div class="inline-inputs step-nine-wrapper" data-test="COB-step9">
    <transition mode="out-in" :name="transitionName">
      <consumer-loan-apply-wizard-fraud-error v-if="fraudCheckError" />

      <consumer-loan-apply-wizard-fraud-decline v-else-if="riskDecisionFailed" />

      <consumer-invalid-info v-else-if="showFraudError" />

      <standard-wizard-message v-else-if="showGucaDecline" title="Application Cancelled">
        <template #body>
          <p>Based on the information you provided you cannot proceed.</p>
        </template>
      </standard-wizard-message>

      <no-credit-info-error v-else-if="isMissingCreditResponse" />

      <consumer-loan-apply-credit-non-decision-msg v-else-if="nonDecision" />

      <consumer-loan-apply-credit-freeze-error v-else-if="creditFrozen" />

      <consumer-loan-apply-wizard-credit-engine-soft-decline-msg
        v-else-if="showDeclineMessage" />

      <consumer-loan-apply-wizard-credit-engine-soft-approved-for-less-msg
        v-else-if="showApprovedForLessAan || showDisclaimerPage"
        :on-consent="nextStep" />

      <consumer-loan-apply-wizard-processing-loan-offers v-else-if="processing || isLoadingFraud">
        <div class="fs-unmask now-processing-text">
          <p>Now processing your loan offers.</p>
          <p>
            Please remain on this screen
            and do not refresh your browser until this process has completed.
          </p>
        </div>
      </consumer-loan-apply-wizard-processing-loan-offers>

      <div v-else>
        <bubble-card>
          <template #card-title>
            <h5 data-test="reviewHeaderLabel">
              Please Review
            </h5>
          </template>
          <h6
            class="font-weight-regular mt-0"
            style="color: var(--grayscale-color-1);"
            data-test="pleaseReviewSubHeaderLabel">
            Please review and make any corrections necessary. Then click "View My Offers."
          </h6>
        </bubble-card>

        <bubble-card card-color="navy">
          <template #card-title>
            <div class="dense-title">
              <h6 data-test="personalInfoHeaderLabel">
                Personal Information
              </h6>
              <div class="edit-btn-wrapper">
                <custom-button
                  variant="text"
                  :disabled="editSaveDisabled"
                  @click="toggleEdit">
                  {{ editing ? 'Save' : 'Edit' }}
                </custom-button>
              </div>
            </div>
          </template>
          <div class="card-text__info-group">
            <currency-input
              v-model="stepData.requested_loan_amount"
              data-test="amountRequestedInput"
              :disabled="formInputsDisabled"
              in-place
              label="Amount Requested"
              :max="maxAmount"
              :min="minAmount"
              currency-symbol-in-mask
              required />
          </div>

          <v-row>
            <v-col cols="6">
              <div class="card-text__info-group">
                <text-input
                  v-model="stepData.first_name"
                  data-test="firstInput"
                  :disabled="formInputsDisabled"
                  in-place
                  label="First Name"
                  required />
              </div>
            </v-col>

            <v-col cols="6">
              <div class="card-text__info-group">
                <text-input
                  v-model="stepData.middle_name"
                  data-test="middleInput"
                  :disabled="formInputsDisabled"
                  in-place
                  label="Middle Name" />
              </div>
            </v-col>
          </v-row>

          <div class="card-text__info-group">
            <text-input
              v-model="stepData.last_name"
              data-test="lastInput"
              :disabled="formInputsDisabled"
              in-place
              label="Last Name"
              required />
          </div>

          <v-row>
            <v-col cols="6">
              <div class="card-text__info-group no-underline">
                <ssn-input
                  v-model="stepData.ssn"
                  disabled
                  in-place
                  readonly
                  label="Social Security Number"
                  required />
              </div>
            </v-col>
            <v-col cols="6">
              <div class="card-text__info-group no-underline">
                <date-input
                  v-model="stepData.dob"
                  :disabled="formInputsDisabled"
                  enforce-dob-check
                  in-place
                  readonly
                  :max="maxDate"
                  label="Date of Birth" />
              </div>
            </v-col>
          </v-row>
        </bubble-card>

        <bubble-card card-color="navy">
          <template #card-title>
            <div class="dense-title">
              <h6 data-test="employmentInfoHeaderLabel">
                Employment Information
              </h6>
              <div class="edit-btn-wrapper">
                <custom-button
                  variant="text"
                  :ripple="false"
                  :disabled="editSaveDisabled"
                  @click="toggleEditEmploymentInfo">
                  {{ editingEmploymentInfo ? 'Save' : 'Edit' }}
                </custom-button>
              </div>
            </div>
          </template>

          <div v-if="stepData.employment_information">
            <div class="card-text__info-group">
              <select-input
                v-model="stepData.employment_information.employment_status"
                :items="employmentStatuses"
                label="Employment Status"
                :disabled="employmentInfoInputsDisabled"
                in-place
                hide-dropdown-icon />
            </div>

            <div v-if="employmentStatusEmployed" class="card-text__info-group">
              <select-input
                v-model="stepData.employment_information.employment_length"
                :items="employmentLength"
                label="Length of Employment"
                :disabled="employmentInfoInputsDisabled"
                in-place
                hide-dropdown-icon />
            </div>

            <v-row v-if="employmentStatusEmployed">
              <v-col cols="8">
                <div class="card-text__info-group">
                  <phone-input
                    v-model="stepData.employment_information.phone_number"
                    label="Employer Phone Number"
                    :disabled="employmentInfoInputsDisabled"
                    in-place
                    required />
                </div>
              </v-col>
              <v-col cols="4">
                <div class="card-text__info-group">
                  <text-input
                    v-model="stepData.employment_information.extension"
                    label="Ext."
                    :disabled="employmentInfoInputsDisabled"
                    inputmode="numeric"
                    in-place
                    required />
                </div>
              </v-col>
            </v-row>

            <div v-if="employmentStatusSelfEmployed">
              <div class="card-text__info-group">
                <select-input
                  v-model="stepData.employment_information.employment_length"
                  :disabled="employmentInfoInputsDisabled"
                  :items="employmentLength"
                  label="Years In Business"
                  :required="isRequiredField"
                  in-place
                  hide-dropdown-icon />
              </div>

              <v-row>
                <v-col cols="8">
                  <div class="card-text__info-group">
                    <phone-input
                      v-model="stepData.employment_information.phone_number"
                      :disabled="employmentInfoInputsDisabled"
                      :error-messages="errors.phone_number"
                      label="Business Phone Number"
                      in-place
                      :required="isRequiredField" />
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="card-text__info-group">
                    <v-text-field
                      v-model="stepData.employment_information.extension"
                      :disabled="employmentInfoInputsDisabled"
                      label="Ext."
                      in-place />
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>

          <div v-if="stepData.employment_information" class="card-text__info-group">
            <annual-gross-input
              v-model="stepData.employment_information.annual_income"
              class="income-input"
              :disabled="employmentInfoInputsDisabled"
              in-place
              currency-symbol-in-mask
              @amountConfirmed="amountConfirmed = true"
              @input="onGrossIncomeInput" />
          </div>
        </bubble-card>

        <div class="submit-my-application">
          <p class="mb-0" data-test="byClickingLabel">
            By clicking "View My Offers" below, I am agreeing to the
            <!-- eslint-disable-next-line vue/max-attributes-per-line -->
            <a :href="craUrl || ''" rel="noopener noreferrer" target="_blank">Credit Report Authorization</a>,
            which authorizes Momnt to order my credit report that will not affect my credit score.
            I affirm that this information is accurate and understand that Momnt may verify it.
          </p>
          <custom-button
            data-test="continueBtn"
            :disabled="saveDisabled"
            class="mt-8"
            full-width
            label="View My Offers"
            @click="processApplication" />
        </div>
      </div>
    </transition>

    <complete-later-button v-if="!isAppInErrorState || !showApprovedForLessAan" />
  </div>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import AnnualGrossInput from '@/components/Consumer/LoanApplyWizard/AnnualGrossInput/index.vue';
import TextInput from '@/components/Inputs/Text.vue';
import DateInput from '@/components/Inputs/Date.vue';
import SsnInput from '@/components/Inputs/Ssn.vue';
import CurrencyInput from '@/components/Inputs/Currency.vue';
import PhoneInput from '@/components/Inputs/Phone.vue';
import SelectInput from '@/components/Inputs/Select.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import GetProcessing from '@/mixins/GetProcessing';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';
import { get, isEmpty, toNumber } from 'lodash';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import moment from 'moment';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import CreditEnginePullTypes from '@/enums/CreditEngine/PullTypes';
import CreditEngineDecisions from '@/enums/CreditEngine/Decisions';
import CreditEngineCheckMixin from '@/mixins/Consumer/LoanApply/CreditEngineCheckMixin';
import securityPin from '@/validators/securityPin';
import validateDate from '@/validators/date';
import ssn from '@/validators/ssn';
import { dateToFormat } from '@/helpers/DateTimeHelper';
import DATE_CONSTANTS from '@/constants/DateConstants';
import { MIN_AGHI_VALUE } from '@/constants/AghiConstants';
import { updateInfo } from '@/api/consumer';
import GetErrors from '@/mixins/GetErrors';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import ReEntryMixin from '@/mixins/Consumer/LoanApply/ReEntryMixin';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import { patchAvailableConsents } from '@/utils/Consents';
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import NoCreditInfoError from '@/components/Consumer/LoanApplyWizard/Messages/NoCreditInfoError.vue';
import InvitationApplicationStatusDetails
  from '@/enums/Consumer/InvitationApplicationStatusDetails';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import EmploymentStatus from '@/enums/Consumer/Employment';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import ConsumerLoanApplyWizardFraudDecline from '@/components/Consumer/LoanApplyWizard/Messages/FraudDecline.vue';
import ConsumerLoanApplyCreditFreezeError from '@/components/Consumer/LoanApplyWizard/Messages/CreditFreezeError.vue';
import ConsumerLoanApplyCreditNonDecisionMsg from '@/components/Consumer/LoanApplyWizard/Messages/CreditNonDecisionMsg.vue';
import ConsumerLoanApplyWizardCreditEngineSoftDeclineMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/CreditEngine/SoftDeclineMsg.vue';
import ConsumerLoanApplyWizardCreditEngineSoftApprovedForLessMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/CreditEngine/SoftApprovedForLessMsg.vue';
import ConsumerLoanApplyWizardProcessingLoanOffers
  from '@/components/Consumer/LoanApplyWizard/Messages/ProcessingLoanOffers.vue';
import ConsumerLoanApplyWizardFraudError from '@/components/Consumer/LoanApplyWizard/Messages/FraudError.vue';
import ConsumerInvalidInfo from '@/components/Consumer/LoanApplyWizard/Messages/ConsumerInvalidInfo.vue';
import { defineComponent } from 'vue';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'StepNine',
  components: {
    BubbleCard,
    StandardWizardMessage,
    NoCreditInfoError,
    AnnualGrossInput,
    TextInput,
    DateInput,
    SsnInput,
    CurrencyInput,
    PhoneInput,
    SelectInput,
    CustomButton,
    CompleteLaterButton,
    ConsumerLoanApplyWizardFraudDecline,
    ConsumerLoanApplyCreditFreezeError,
    ConsumerLoanApplyCreditNonDecisionMsg,
    ConsumerLoanApplyWizardCreditEngineSoftDeclineMsg,
    ConsumerLoanApplyWizardCreditEngineSoftApprovedForLessMsg,
    ConsumerLoanApplyWizardProcessingLoanOffers,
    ConsumerLoanApplyWizardFraudError,
    ConsumerInvalidInfo,
  },
  mixins: [
    GetProcessing,
    GetErrors,
    CreditEngineCheckMixin,
    IdleTimeoutMixin,
    ReEntryMixin,
    NavigatesStepsMixin,
    HoneypotTrackMixin,
  ],
  data() {
    const stepData: LoanApplyDataInterface = {};
    const initialStepData: LoanApplyDataInterface = {};

    return {
      ownStep: 9,
      showFraudCheckError: false,
      showFetchingLoans: false,
      editing: false,
      minAmount: 3100,
      maxAmount: 55000,
      stepData,
      initialStepData,
      amountConfirmed: true,
      showDisclaimerPage: false,
      otpSimpleDesign: true,
      showFraudError: false,
      isLoadingFraud: false,
      gucaCheckDecline: false,
      showGucaDecline: false,
      disableButton: false,
      editingEmploymentInfo: false,
      craUrl: '',
      socureEmailError: 'Invalid email. Please resubmit the transaction using an email address conforming to RFC 5322.',
      hpEventName: 'Submit Application Page Visit',
      hpStep: 9,
      employmentStatuses: [
        EmploymentStatus.EMPLOYED,
        EmploymentStatus.SELF_EMPLOYED,
        EmploymentStatus.RETIRED,
        EmploymentStatus.UNEMPLOYED,
        EmploymentStatus.OTHER,
      ],
      phoneNumberRequiredStatuses: [
        EmploymentStatus.EMPLOYED,
        EmploymentStatus.SELF_EMPLOYED,
      ],
      employmentLength: [
        'Less than 1 year',
        '1-3 years',
        '3-5 years',
        'Greater than 5 years',
      ],
    };
  },
  computed: {
    maxDate(): string {
      return moment().format(DATE_CONSTANTS.dateFormat);
    },
    transitionName(): string {
      return this.editing ? '' : 'slide';
    },
    getConsumerLoanApplyStateMinAge(): number {
      const state = get(this.$store.getters['Consumer/getLoanApplyWizardData'], 'state.value');

      if (!isEmpty(state) && (state === 'AL' || state === 'NE')) {
        return 19;
      }
      return 18;
    },
    consumerWizarData(): LoanApplyDataInterface {
      return this.$store.getters['Consumer/getLoanApplyWizardData'];
    },
    formInputsDisabled(): boolean {
      return !this.editing || this.processing;
    },
    isGrossIncomeValid(): boolean {
      const value = toNumber(this.stepData.employment_information?.annual_income || 0);
      if (!value || value < 0) return false;
      if (value < MIN_AGHI_VALUE && !this.amountConfirmed) return false;
      return true;
    },
    isPinValid(): boolean {
      return securityPin(get(this.stepData, 'security_pin', ''));
    },
    isDobValid(): boolean {
      const minDob = this.getConsumerLoanApplyStateMinAge;
      const opts = {
        maxDate: this.maxDate,
        dob: true,
        minDob,
      };

      return validateDate(this.stepData.dob, opts).isValid;
    },
    isSsnValid(): boolean {
      return ssn(get(this.stepData, 'ssn', ''));
    },
    isRequestedAmountValid(): boolean {
      const requestedLoanAmount = this.stepData.requested_loan_amount ?? 0;
      return Number(requestedLoanAmount) >= this.minAmount
      && Number(requestedLoanAmount) <= this.maxAmount;
    },
    isNameValid(): boolean {
      return !!this.stepData.first_name && !!this.stepData.last_name;
    },
    saveDisabled(): boolean {
      return this.editing || this.editSaveDisabled || this.disableButton;
    },
    merchantUuid(): string {
      return this.invitation.merchant;
    },
    editSaveDisabled(): boolean {
      return !(
        this.isPinValid
        && this.isRequestedAmountValid
        && this.isNameValid
        && this.isDobValid
        && this.isGrossIncomeValid
        && this.isSsnValid
      );
    },
    isLoanStackingEnabled(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.LOAN_STACKING);
    },
    isGucaEnabled(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.USE_GUCA);
    },
    loanOptions(): Array<RateSheetData> {
      return this.$store.getters['Consumer/getLoanOptions'];
    },
    nonDecision(): boolean {
      return this.softPullData.decision === CreditEngineDecisions.NON_DECISION;
    },
    isMissingCreditResponse(): boolean {
      return this.nonDecision
        && this.softPullData.reasons === 'no eligible_lenders field in credit_engine_response';
    },
    fraudCheckData(): any {
      return this.$store.getters['Consumer/getFraudCheck'];
    },
    riskDecision(): string {
      if (this.fraudCheckData.decision) {
        return this.fraudCheckData.decision.value || '';
      }
      return this.fraudCheckData.risk_decision || '';
    },
    fraudMethod(): string {
      return this.fraudCheckData.fraud_method_used;
    },
    missingParameterFraudCheck(): any {
      return this.fraudCheckData.missing_parameter || '';
    },
    riskDecisionFailed(): boolean {
      if (this.softPullData?.decision === CreditEngineDecisions.DECLINED_SSN_MISMATCH) {
        return true;
      }

      if (this.fraudMethod === 'Socure') {
        return this.riskDecision !== 'accept' || this.missingParameterFraudCheck;
      }
      return this.riskDecision === 'fail' || this.missingParameterFraudCheck;
    },
    softPullData() {
      return this.creditEngineCheck[CreditEnginePullTypes.SOFT] || {};
    },
    softDeclined(): boolean {
      return this.softPullData.decision === CreditEngineDecisions.DECLINED;
    },
    softHardDeclined(): boolean {
      return this.softPullData.decision === CreditEngineDecisions.HARD_DECLINED;
    },
    creditFrozen(): boolean {
      return get(this.creditEngineCheck, `[${CreditEnginePullTypes.SOFT}].decline_type`, '') === 'frozen_file';
    },
    fraudCheckError(): any {
      return this.$store.getters['Consumer/getFraudCheckError'];
    },
    softApproved(): boolean {
      return this.softPullData.decision === CreditEngineDecisions.APPROVED;
    },
    softApprovedForLess(): boolean {
      return this.softPullData.decision === CreditEngineDecisions.APPROVED_FOR_LESS;
    },
    showDeclineMessage(): boolean {
      return this.softDeclined
        || this.softHardDeclined
        || (this.softApproved && !this.showFetchingLoans && this.loanOptions.length === 0)
        || (this.softApprovedForLess && !this.showFetchingLoans && this.loanOptions.length === 0);
    },
    showApprovedForLessAan(): boolean {
      return this.softApprovedForLess && !this.showFetchingLoans && this.loanOptions.length > 0;
    },
    cardElevation() {
      return this.editing ? 20 : 4;
    },
    brandData(): any {
      return this.$store.getters['Ui/getBrandingObject'];
    },
    employmentStatusEmployed(): boolean {
      return this.stepData.employment_information?.employment_status
        === EmploymentStatus.EMPLOYED;
    },
    employmentStatusSelfEmployed(): boolean {
      return this.stepData.employment_information?.employment_status
        === EmploymentStatus.SELF_EMPLOYED;
    },
    employmentInfoInputsDisabled(): boolean {
      return !this.editingEmploymentInfo || this.processing;
    },
    isRequiredField(): boolean {
      return this.stepData.employment_information?.employment_status === EmploymentStatus.EMPLOYED;
    },
    isAppInErrorState(): boolean {
      return this.softDeclined || this.fraudCheckError || this.riskDecisionFailed
      || this.showFraudError || this.showGucaDecline || this.isMissingCreditResponse
      || this.creditFrozen || this.showDeclineMessage || this.nonDecision;
    },
  },
  watch: {
    brandData: {
      handler(newVal: any) {
        if (newVal.credit_report_authorization_url) {
          this.craUrl = newVal.credit_report_authorization_url;
        }
      },
      immediate: true,
    },
  },
  async created() {
    if (
      this.isReEntry
    && this.appStatusDetail === InvitationApplicationStatusDetails.SOFT_PULL_FROZEN_CREDIT_FILE
    ) {
      this.$store.dispatch('Consumer/setApplicationValid', false);
      await this.initiateSoftPull();
    }
  },

  async mounted() {
    if (this.isReEntry) await this.getEmploymentInfo();
    const data = this.$store.getters['Consumer/getLoanApplyWizardData'];
    this.showDisclaimerPage = this.$store.getters['Consumer/getShowDisclaimerPage'];

    this.stepData.requested_loan_amount = data.requested_loan_amount;
    this.stepData.first_name = data.first_name;
    this.stepData.middle_name = data.middle_name || 'N/A';
    this.stepData.last_name = data.last_name;
    this.stepData.ssn = data.ssn;
    this.stepData.security_pin = data.security_pin;

    this.stepData.employment_information = data.employment_information;

    const dob = dateToFormat(data.dob);
    this.stepData.dob = dob;

    // Save initial data for patch logic to check for difference
    this.initialStepData = { ...this.stepData };

    this.completeReEntry();

    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },

  methods: {
    async nextStep() {
      this.goToStep(this.ownStep + 1);
    },
    onGrossIncomeInput(value: string) {
      this.stepData.gross_income = value;
      this.amountConfirmed = false;
    },
    onSecurityPinUpdated(val: any): void {
      this.stepData.security_pin = val;
    },
    toggleEdit() {
      this.editing = !this.editing;
      this.stepData.middle_name = this.stepData.middle_name || 'N/A';
      if (this.editing && this.stepData.middle_name === 'N/A') this.stepData.middle_name = '';
    },
    updateConsents(consents: Array<number>): Promise<number> {
      const appId = this.$store.getters['Consumer/getConsumerApplicationId'];
      return patchAvailableConsents(consents, appId, ConsentEntityTypes.CONSUMER);
    },
    async setEmploymentInfo(): Promise<void> {
      const { data } = await this.$store.dispatch('Consumer/setEmploymentInformation', this.stepData.employment_information);
      return data;
    },
    async getEmploymentInfo(): Promise<void> {
      const { data } = await this.$store.dispatch('Consumer/getEmploymentInformation');
      return data;
    },
    async toggleEditEmploymentInfo() {
      this.editingEmploymentInfo = !this.editingEmploymentInfo;

      if (!this.editingEmploymentInfo) {
        await this.setEmploymentInfo();
      }
    },
    async processApplication() {
      this.disableButton = true;

      this.$store.dispatch('Consumer/setApplicationValid', false);

      const appId = this.$store.getters['Consumer/getConsumerApplicationId'];
      const data = {
        dob: this.stepData.dob,
        first_name: this.stepData.first_name,
        last_name: this.stepData.last_name,
        middle_name: this.stepData.middle_name?.toUpperCase() === 'N/A' ? '' : this.stepData.middle_name,
        requested_loan_amount: this.stepData.requested_loan_amount,
        security_pin: this.stepData.security_pin,
        ssn: this.stepData.ssn,
        gross_income: this.stepData.employment_information?.annual_income,
      };

      // The backend expects the patch DOB to be in YYYY-MM-DD format.
      if (data) data.dob = dateToFormat(data.dob || '', DATE_CONSTANTS.dateFormatIso);

      if (Object.keys(data).length) {
        let updated = true;
        await updateInfo(appId, data).catch((error: any) => {
          this.$store.dispatch('Ui/setErrors', error.response);
          updated = false;
        });
        if (!updated) return;
      }

      // Log consent
      const consents = [ConsentTypesEnum.CREDIT_REPORT_AUTHORIZATION];
      await this.updateConsents(consents);

      // All is well, update consumer store with right data.
      this.$store.commit('Consumer/setLoanApplyWizardData', this.stepData);

      // Get Socure SessionID token
      const socureSessionIDToken = sessionStorage.getItem('socureDeviceSessionToken');

      if (this.isGucaEnabled) {
        const gucaPayload = {
          consumer_application: appId,
          originating_merchant: this.merchantUuid,
          check_against_merchant: true,
          check_against_consumer: true,
          ssn: this.consumerWizarData.ssn,
          phone_number: this.consumerWizarData.phone_number,
          email: this.consumerWizarData.email,
          address: [{
            address_1: this.consumerWizarData.address_1,
            address_2: this.consumerWizarData.address_2,
            city: this.consumerWizarData.city,
            state: this.consumerWizarData.state,
            zip_code: this.consumerWizarData.zip_code,
          }],
        };

        const gucaCheck = await this.$store.dispatch('Auth/gucaCheckApi', gucaPayload);

        this.gucaCheckDecline = gucaCheck.data?.decline_application
          || (gucaCheck.data?.positive_hit && gucaCheck.data?.originating_merchant_hit === true);

        if (this.gucaCheckDecline) {
          await this.$store.dispatch('Consumer/declineApplication', appId);
          this.showGucaDecline = true;
          return;
        }
      }

      if (this.isLoanStackingEnabled) {
        const loanStacking = await this.$store.dispatch('Consumer/loanStacking');

        if (loanStacking.data?.decline_application) {
          await this.$store.dispatch('Consumer/declineApplication', appId);
          this.showGucaDecline = true;
          return;
        }
      }

      const fraudCheckResp = await this.$store.dispatch('Consumer/fraudCheck', socureSessionIDToken);

      if (this.riskDecision === 'pass' || this.riskDecision === 'accept') {
        await this.initiateSoftPull();
      } else {
        this.isLoadingFraud = true;

        /* eslint-disable no-lonely-if */
        if (fraudCheckResp.error && this.fraudMethod !== 'Socure') {
          if (fraudCheckResp.error.response.data.detail === this.socureEmailError) {
            this.isLoadingFraud = false;
            this.showFraudError = true;
          } else {
            this.$router.push({ name: 'error-page' });
          }
        } else if (fraudCheckResp.data.details || fraudCheckResp.data.decision?.details) {
          if (fraudCheckResp.data.details.socure_error === this.socureEmailError
          || fraudCheckResp.data.decision?.details.socure_error === this.socureEmailError) {
            this.isLoadingFraud = false;
            this.showFraudError = true;
          } else if (!this.riskDecisionFailed) {
            this.$router.push({ name: 'error-page' });
          }
        } else if (
          fraudCheckResp.status === 500
          || (fraudCheckResp.status === 200 && fraudCheckResp.data.risk_decision === 'fail')
        ) {
          this.$router.push({ name: 'error-page' });
        }
      }
    },
    async initiateSoftPull() {
      const responseStatusCreditEngine = await this.softPull();

      if (!responseStatusCreditEngine.error && (this.softApproved || this.softApprovedForLess)) {
        this.showFetchingLoans = true;

        const { status } = await this.$store.dispatch('Consumer/getApprovedLoans');
        this.showFetchingLoans = false;

        if (
          status === 200 && this.loanOptions.length > 0
          && (this.softApproved || this.softApprovedForLess)) {
          this.$store.dispatch('Consumer/setApplicationValid', true);
          if (!this.softApprovedForLess) await this.nextStep();
        }
      }
    },
    softPull() {
      return this.$store.dispatch('Consumer/creditEnginePull', CreditEnginePullTypes.SOFT);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/inline-inputs";
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/_custom-transitions";
@import '@/assets/scss/mixins/media_queries';

.step-nine-wrapper {
  margin-bottom: 1rem;

  .consumer-edit-data {
    border: 1px solid var(--grayscale-color-2);
    .card-text {
      padding: 1.25rem;
    }
  }
}

.bubble-card {
  margin-bottom: 1rem;
}

:deep(.bubble-card__title .dense-title) {
  min-height: 1rem;
  height: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h6 {
    margin: 0 !important;
  }
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.standard-wizard {
  &__step {
    @include standard-wizard-form-inline-split;
  }
}

.standard-input-field.standard-input-otp {
  padding: 0;
  border: none;
  margin-bottom: 0;
}

.custom-button.standard-toggle-edit-form-button.v-btn.v-btn--has-bg.theme--light.v-size--default {
  &.button-primary.button-large.standard-toggle-edit-form-button--inactive {
    background-color: white;
    color: black;
  }
}

.edit-btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

:deep(.edit-btn-wrapper button:hover) {
  background-color: unset !important;
}

.submit-my-application {
  display: block;
  margin: 2rem auto 0;
  max-width: 29.25rem;

  @include tablet {
    max-width: 26.25rem;
  }
}

.card-text__info-group__title {
  font-size: 1rem;
}

.now-processing-text p {
  font-size: 1rem;
}

.income-input :deep(.standard-input-field__input-group) {
  height: 2.7rem;
}

:deep(.no-underline) {
  .v-input__slot::before {
    border: 0 !important;
  }

  .v-label--active {
    color: var(--grayscale-color-1) !important;
  }

  .v-input--is-dirty, .v-input--is-focused.primary--text {
    color: transparent !important;
  }
}

:deep(.card-text__info-group input) {
  font-weight: bold;
}

:deep(.v-select__selection) {
  font-weight: bold;
  color: var(--grayscale-color-1) !important;
}
</style>
