<template>
  <div class="rate-sheet-options-expandable-categories" data-test="rate-sheet-options">
    <v-expansion-panels
      v-if="expandableCategories.length > 0"
      :model-value="!isCollapseOffersCOBEnabled ? toggledExpansionPanel : expandedPanels"
      variant="accordion"
      :multiple="isCollapseOffersCOBEnabled && !isFromOfferCodes"
      @update:model-value="toggleExpansionPanel">
      <v-expansion-panel
        v-for="(category, categoryIndex) in expandableCategories"
        :key="categoryIndex"
        class="standard-collapsable-panel"
        :data-test="`expansionPanels${categoryIndex}`">
        <v-expansion-panel-title
          class="standard-collapsable-panel__title"
          :data-test="`productExpandButton${categoryIndex}`">
          {{ category.label || category }}
          <v-icon
            v-if="category.has_fallback"
            size="1.5rem"
            color="var(--grayscale-color-1)"
            @click.stop="$emit('show-fallback-modal')">
            mdi-information-outline
          </v-icon>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <v-radio-group
            v-if="showCards"
            :model-value="value"
            @update:model-value="onToggle">
            <cards
              v-for="rateSheet in getCategoryRateSheetsCards(category)"
              :key="rateSheet.uuid"
              :value="value"
              :rate-sheet="rateSheet"
              :disabled="disabled"
              :option-selection="optionSelection"
              :is-buffer-amount="isBufferAmount"
              :approved-amount="approvedAmount"
              :requested-amount="requestedAmount"
              :selected-ratesheet="selectedRatesheet"
              @select="onToggle" />
          </v-radio-group>

          <div v-if="showTables">
            <tables
              v-model="inputVal"
              :rate-sheet-options="getCategoryRateSheetsTables(category)"
              :disabled="disabled"
              :button-toggle="buttonToggle"
              :disable-add-btns="disableAddBtns"
              :mobile-breakpoint="mobileBreakpoint"
              :is-from-offer-codes="isFromOfferCodes"
              :data-test-attr="category.dataTest"
              @toggle="$emit('toggle', $event)" />
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Cards from '@/components/RateSheetOptions/ExpandableCategories/Cards.vue';
import cloneDeep from 'lodash/cloneDeep';
import GetProcessing from '@/mixins/GetProcessing';
import { RATE_SHEET_CATEGORIES } from '@/constants/RateSheetsBaseConstants';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import StandardInputMixin from '@/mixins/StandardInputMixin';
import Tables from '@/components/RateSheetOptions/ExpandableCategories/Tables.vue';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';

export default defineComponent({
  name: 'RateSheetOptionsExpandableCategories',

  components: {
    Cards,
    Tables,
  },

  mixins: [
    FeatureFlagsMixin,
    GetProcessing,
    StandardInputMixin,
  ],

  props: {
    rateSheetOptions: { type: Array<RateSheetData>, default: () => [] },
    onSelectItem: { type: Function, default: () => null },
    showCards: { type: Boolean, default: true },
    showTables: { type: Boolean, default: false },
    isBufferAmount: { type: Boolean, default: false },
    requestedAmount: { type: [Number, String], default: 0 },
    approvedAmount: { type: Number, default: 0 },
    buttonToggle: { type: Boolean, default: false },
    disableAddBtns: { type: Boolean, default: false },
    mobileBreakpoint: { type: Number, default: 600 },
    isFromOfferCodes: { type: Boolean, default: false },
    optionSelection: { type: Boolean, default: false },
  },

  data() {
    return {
      selectedRatesheet: '',
      expandedPanels: [] as number[],
      showFallbackModal: false,
    };
  },

  computed: {
    rateSheets(): Array<RateSheetData> {
      return this.$store.getters['MerchantPortal/getRateSheets'];
    },

    toggledExpansionPanel() {
      if (this.isFromOfferCodes) {
        return this.expandedPanels;
      }
      return this.$store.getters['MerchantPortal/getRateSheetExpandableCategory'];
    },

    expandableCategories(): any[] {
      let expandableCategories: any = [];
      const categories = cloneDeep(RATE_SHEET_CATEGORIES);
      if (!this.isFeatureEnabled(FeatureFlagsConstants.MERCHANT_DEFERRED_INTEREST)) {
        categories.deferredInterest.label = 'Same-As-Cash';
        categories.deferredInterest.categoryId = 'sameAsCash';
        categories.deferredInterest.dataTest = 'rateTable-SameAsCash';
      }

      if (!this.isFeatureEnabled(FeatureFlagsConstants.USE_FALLBACK_PRODUCTS)) {
        categories.deferredInterest.has_fallback = false;
      }

      if (this.showCards) {
        this.rateSheetOptions.forEach((rateSheet: RateSheetData) => {
          const { category } = rateSheet.json_description || {};
          if (!expandableCategories.includes(category)) expandableCategories.push(category);
        });
      } else if (this.showTables) {
        categories.brandingPlus.label = `${this.brandingData.brand_name}+`;
        expandableCategories = Object.values(categories);
      }

      return expandableCategories.filter(Boolean);
    },

    inputDisabled(): boolean {
      return this.disabled || this.processing;
    },

    brandingData(): any {
      return this.$store.getters['Ui/getBrandingObject'];
    },

    isCollapseOffersCOBEnabled(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.COLLAPSE_OFFERS_COB);
    },
  },

  mounted() {
    if (this.isFromOfferCodes) {
      this.expandedPanels = [0];
    }
  },

  methods: {
    onToggle(item: RateSheetData) {
      this.selectedRatesheet = item.product_name ? item.product_name : '';
      const value = item === (this.value as unknown) ? {} : item;
      this.$emit('change', value);
      this.onSelectItem(value);
    },

    getCategoryRateSheetsCards(category: string): Array<RateSheetData> {
      return this.rateSheetOptions.filter((rateSheet: RateSheetData) => {
        return rateSheet.json_description?.category === category;
      });
    },

    getCategoryRateSheetsTables(category: any): Array<RateSheetData> {
      return this.rateSheetOptions.filter((rateSheet: RateSheetData) => {
        const productType = rateSheet.product_type.toLowerCase();
        const { ids } = category;
        const isPlus = rateSheet.is_plus;
        if (isPlus) {
          return category.plus === isPlus;
        }
        return ids.find((id: string) => id.toLowerCase() === productType);
      });
    },

    toggleExpansionPanel(value: number | number[]) {
      if (this.isBufferAmount) {
        this.$store.dispatch('MerchantPortal/setRSExpandableCategory', 0);
      }

      if (value === undefined && !this.isFromOfferCodes) {
        this.$store.dispatch('MerchantPortal/setRSExpandableCategory', -1);
      }

      if (!this.isFromOfferCodes) {
        const baseRate = this.rateSheets.map((element: RateSheetData) => {
          element.has_ripple = false;
          return element;
        });

        this.$store.dispatch('MerchantPortal/setRateSheetsRipple', baseRate);
        this.$store.dispatch('MerchantPortal/setRSExpandableCategory', value);
        this.expandedPanels = Array.isArray(value) ? value : [value];
      } else {
        this.expandedPanels = Array.isArray(value) ? value : [value];
      }

      this.$emit('panels-opened', this.expandedPanels.length);
    },

    expandAllPanels() {
      this.expandedPanels = this.expandableCategories.map((_, index) => index);
    },

    collapseAllPanels() {
      this.expandedPanels = [-1];
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/variables/font-variables";
@import "@/assets/scss/mixins/mixins";

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;

  .v-input {
    padding: 0 0.5rem 1rem;
  }
}

.rate-sheet-options-expandable-categories__card {
  border: none;
  border-radius: 4px;
  margin: 0;
  margin-top: 1.5rem;
}

.rate-sheet-options-expandable-categories {
  :deep(.v-expansion-panels) {
    .standard-collapsable-panel {
      margin-bottom: 0;
    }

    .standard-collapsable-panel__title {
      border-radius: 0 0 5px 5px;
      min-height: 5rem;
      font-style: normal;
      font-weight: 900;
      font-size: $font-size-standard;
      line-height: 100%;
      color: var(--secondary-color);
      font-family: $font-family-sans-serif-name;

      .v-icon {
        margin-left: 1rem;
      }
    }

    :deep(.standard-collapsable-panel__title--2) {
      font-family: 'Source Sans Pro';
      font-size: 1.1rem;
      color: var(--primary-color) !important;
      background-color: var(--grayscale-color-5);
      font-weight: 400;

      .v-expansion-panel-title {
        .v-icon {
          color: var(--primary-color) !important;
        }
      }
    }

    :deep(.standard-collapsable-panel__title--2.v-expansion-panel-title--active) {
      color: var(--grayscale-color-1) !important;

      .v-expansion-panel-title {
        .v-icon {
          color: var(--grayscale-color-1) !important;
        }
      }
    }

    .v-expansion-panel-title {
      .v-icon {
        color: var(--secondary-color);
        font-size: 1.75rem;
      }
    }
  }
}
</style>
