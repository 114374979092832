<template>
  <v-text-field
    ref="input"
    v-mask="inputMask"
    v-bind="attrs"
    autocomplete="off"
    :data-test="dataTestAttr"
    :disabled="disabled"
    :hint="hint"
    class="currency-input"
    persistent-hint
    :label="labelAttr"
    :variant="variantAttr"
    :prepend-inner-icon="showIcon ? 'mdi-currency-usd' : ''"
    :rules="validationRules"
    validate-on="blur"
    :model-value="modelValue"
    @click="onClick"
    @keydown="onKeyDown">
    <template v-if="showLearnMore" v-slot:message>
      <slot />
    </template>
  </v-text-field>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import Inputmask from 'inputmask';

export default defineComponent({
  name: 'CurrencyInput',

  mixins: [
    InputFieldMixin,
  ],

  props: {
    modelValue: { type: [String, Number], default: '' },
    hint: { type: String, default: '' },
    min: { type: Number, default: null },
    max: { type: Number, default: null },
    showIcon: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    allowCents: { type: Boolean, default: false },
    currencySymbolInMask: { type: Boolean },
    rules: { type: Array as PropType<Array<any>> | null, default: null },
    // Where is this used?
    errorMessages: { type: [String, Array] as PropType<string | string[]>, default: '' },
    showLearnMore: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      defaultLabelAttr: 'Amount',
    };
  },

  computed: {
    variantAttr() {
      if (this.inPlace) {
        return this.disabled ? 'plain' : 'underlined';
      }
      return 'outlined';
    },
    inputMask() {
      const inputMask: any = {
        showMaskOnHover: false,
        alias: 'numeric',
        groupSeparator: ',',
        digitsOptional: false,
        rightAlign: false,
        autoUnmask: true,
        digits: 0,
        allowMinus: false,
        greedy: true,
        allowPlus: false,
        prefix: this.currencySymbolInMask ? '$' : undefined,
        definitions: {
          0: { validator: '0' }, // disable editing the .00 part of the mask
        },
        radixPoint: '.',
        positionCaretOnClick: 'radixFocus',
        _mask: () => '(,999){*|1}', // remove the leading zero
        onBeforePaste: (value: string) => {
          const v = Number(value.replaceAll(',', ''));
          return v.toFixed(2);
        },
      };

      if (this.allowCents) {
        inputMask.alias = 'decimal';
        inputMask.numericInput = true;
        inputMask.positionCaretOnClick = 'lvp';
        inputMask.digits = Number(this.modelValue) ? 2 : 1;
        // eslint-disable-next-line no-underscore-dangle
        delete inputMask._mask;
        delete inputMask.definitions;
      }

      return inputMask;
    },

    validationRules() {
      return this.rules ? this.rules : [this.currencyValidation];
    },
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newVal?: string, oldVal?: string) {
        if (this.allowCents) {
          const num = Number(newVal);

          if (!newVal || num === 0 || newVal === '.00') {
            this.updateAndResetMask('');
          } else if (!oldVal) {
            // Dividing this for the first input (where newVal exists and oldVal does not)
            // so that we have 0.01 instead of 0.1
            /**
             * This may look dumb, it's equivalent to num / 10
             * But if fixes the case 0.7 / 10 = 0.069999
             * Transforming the number to int and then dividing by 100 avoids this issue
             */
            const val = (num * 10) / 100;
            this.updateAndResetMask(val.toFixed(2));
          }
        }
        if (newVal === '.00') {
          this.updateAndResetMask('');
        }
      },
    },
  },

  mounted() {
    // aligns the label above the icon even if it's not outlined
    if (this.showIcon && !this.outlined) {
      (this.$refs.input as any).prependWidth = 24;
    }
  },

  methods: {
    resetInputMask() {
      const input = (this.$refs.input as any);
      if (!input) return;

      const el = (this.$refs.input as any).$el.querySelector('input')!;
      el.inputmask!.remove();
      Inputmask(this.inputMask).mask(el);
    },

    onClick() {
      this.$emit('click');
      this.moveCaretFromCentsToRadix();
    },

    onKeyDown(event: KeyboardEvent) {
      if (event.key === '+' || event.key === '-') {
        event.stopImmediatePropagation();
        event.preventDefault();
      }

      if (this.allowCents && !this.modelValue && event.key === '0') {
        // In this case a user entered the 0 key as the first digit in the currency input which
        // would trigger the underlying text-field to set its internal value to `isDirty: true`.
        // And because of the way that `allowCents` input works we would not have a way to reset
        // that state therefor we skip setting it in the first place with this.
        event.preventDefault();
      }
    },

    moveCaretFromCentsToRadix() {
      if (this.allowCents) return;

      const el = (this.$refs.input as any).$el.querySelector('input')!;
      const start = el.selectionStart;
      if (!Number.isInteger(start)) return;

      const end = el.selectionEnd;
      if (start !== end) return; // multiple characters selected

      const [, formatted] = CurrencyFormatLong(el.value).split('$');
      const dotPosition = formatted.length - 3;
      if (start! < dotPosition) return; // not clicked in decimal area

      window.setTimeout(() => el.setSelectionRange(dotPosition, dotPosition), 0);
    },

    currencyValidation(value: string | number | undefined): string | boolean {
      if (!value) return this.isRequired();

      value = Number(value);
      if (Number.isNaN(value)) return 'Not a number';

      if (this.min !== null && value < this.min) return `Value needs to be more than ${CurrencyFormatLong(this.min)}`;
      if (this.max !== null && value > this.max) return `Value needs to be less than ${CurrencyFormatLong(this.max)}`;

      return true;
    },

    updateAndResetMask(value: string | number) {
      this.$emit('update:model-value', value);
      this.resetInputMask();
    },
  },
});
</script>

<style lang="scss" scoped>
.currency-input {
  :deep() {
    .v-input__prepend-inner {
      padding-right: 0;
    }

    .v-input__details {
      padding: 0;

      .v-messages .v-messages__message {
        line-height: 1.15rem;
        color: var(--grayscale-color-1);
      }

      .error--text .v-messages__message {
        color: var(--error-color);
        padding: 0 0.75rem;
      }
    }
  }
}
</style>
