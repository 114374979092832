<template>
  <simple-card
    :class="[
      { 'select-offer-selected': selected },
      'rate-sheet-options-expandable-categories__card',
    ]">
    <template #header>
      <div class="rate-sheet-options-expandable-categories__card__header">
        <div class="rate-sheet-options-expandable-categories__card__header__title">
          {{ rateSheet.json_description.offer }}
        </div>

        <div class="rate-sheet-options-expandable-categories__card__header__more">
          <rate-sheet-options-more-data
            data-test="moreBtn"
            :disabled="disabled"
            :on-cta="selectItem"
            :rate-sheet-option="rateSheet"
            :option-selection="optionSelection" />
        </div>
      </div>
    </template>

    <template #body>
      <div class="rate-sheet-options-expandable-categories__card__body">
        <div
          class="rate-sheet-options-expandable-categories__card__body
         rate-sheet-options-expandable-categories__card__body--during">
          <div class="rate-sheet-options-expandable-categories__card__body__header">
            {{ rateSheet.json_description.promo_header }}
          </div>

          <div class="rate-sheet-options-expandable-categories__card__body__cards">
            <card-details
              v-for="(card, index) in cardsDuring"
              :key="index"
              :card="card" />
          </div>
        </div>

        <div
          class="rate-sheet-options-expandable-categories__card__body
         rate-sheet-options-expandable-categories__card__body--after">
          <div class="rate-sheet-options-expandable-categories__card__body__header">
            {{ rateSheet.json_description.standard_header }}
          </div>

          <div class="rate-sheet-options-expandable-categories__card__body__cards">
            <card-details
              v-for="(card, index) in cardsAfter"
              :key="index"
              :card="card" />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="rate-sheet-options-expandable-categories__card__footer">
        <div class="rate-sheet-options-expandable-categories__card__footer__header">
          {{ footerHeaderLabel }}
        </div>

        <div class="rate-sheet-options-expandable-categories__card__footer__info">
          {{ rateSheet.json_description.note }}
        </div>
      </div>

      <div v-if="isBufferAmount">
        <v-expansion-panels variant="accordion">
          <v-expansion-panel class="compare-panel standard-collapsable-panel">
            <v-expansion-panel-title class="rate-sheet-options-expandable-categories__card__header compare-payments">
              <span>
                Compare Payments at
                <span v-private>{{ CurrencyFormatLong(requestedAmount) }}</span> and
                <span v-private>{{ CurrencyFormatLong(approvedAmount) }}</span>
              </span>
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <span class="compare-panel__cont-text">
                APR and Term remain the same regardless of spend amount.
              </span>

              <div class="compare-panel__table">
                <loan-compare-table :items="comparisonArray" />
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <custom-button
        variant="secondary"
        class="select-offer"
        :class="{ 'select-offer-selected': selected }"
        @click="selectItem">
        {{ selectionButtonText }}
      </custom-button>
    </template>
  </simple-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import CardDetails from '@/components/RateSheetOptions/ExpandableCategories/CardsCardDetails.vue';
import RateSheetOptionsMoreData from '@/components/RateSheetOptions/MoreData.vue';
import SimpleCard from '@/components/SimpleCard.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import LoanCompareTable from '@/components/RateSheetOptions/ExpandableCategories/LoanCompareTable.vue';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import CreditEngineSoftPullApprovedAmountMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineSoftPullApprovedAmountMixin';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'RateSheetCards',

  components: {
    CardDetails,
    RateSheetOptionsMoreData,
    SimpleCard,
    LoanCompareTable,
    CustomButton,
  },

  mixins: [
    CreditEngineSoftPullApprovedAmountMixin,
  ],

  props: {
    disabled: { type: Boolean, default: false },
    rateSheet: { type: Object as PropType<RateSheetData>, default: () => ({}) },
    optionSelection: { type: Boolean, default: false },
    isBufferAmount: { type: Boolean, default: false },
    requestedAmount: { type: [Number, String], default: 0 },
    approvedAmount: { type: Number, default: 0 },
    selectedRatesheet: { type: String, default: '' },
  },

  data() {
    const comparisonArray: Array<any> = [];
    return {
      comparisonArray,
      selected: false,

    };
  },

  computed: {
    cardsDuring(): any {
      const {
        promo_payment_header,
        promo_payment,
        promo_apr_header,
        promo_apr,
        promo_term_header,
        promo_term,
      } = this.rateSheet.json_description || {};
      return [
        {
          header: promo_payment_header,
          value: promo_payment,
        },
        {
          header: promo_apr_header,
          value: promo_apr,
        },
        {
          header: promo_term_header,
          value: promo_term,
        },
      ];
    },

    footerHeaderLabel(): string {
      const { total_term_header = '', total_term = '' } = this.rateSheet.json_description || {};
      return `${total_term_header}: ${total_term}`;
    },

    loanApplyWizardData(): LoanApplyDataInterface {
      return this.$store.getters['Consumer/getLoanApplyWizardData'];
    },

    requestedLoanAmount(): string | number {
      return this.loanApplyWizardData?.requested_loan_amount || '';
    },

    cardsAfter(): any {
      const {
        standard_payment_header,
        standard_payment,
        standard_apr_header,
        standard_apr,
        standard_term_header,
        standard_term,
      } = this.rateSheet.json_description || {};
      return [
        {
          header: standard_payment_header,
          value: standard_payment,
        },
        {
          header: standard_apr_header,
          value: standard_apr,
        },
        {
          header: standard_term_header,
          value: standard_term,
        },
      ];
    },

    selectionButtonText() {
      return this.selected ? 'Selected' : 'Select This Offer';
    },
  },

  watch: {
    rateSheet: {
      immediate: true,
      handler(val: any) {
        const desc = val.json_description;
        if (desc.comparison_standard_payment) {
          this.comparisonArray.push({
            spend: this.requestedLoanAmount,
            promo: desc.comparison_promo_payment,
            standard: desc.comparison_standard_payment,
          });
        }
        this.comparisonArray.push({
          spend: this.approvedAmountSoftPull,
          promo: desc.promo_payment,
          standard: desc.standard_payment,
        });
      },
    },
    selectedRatesheet: {
      immediate: true,
      handler(val: string) {
        if (val !== this.rateSheet.product_name) {
          this.selected = false;
        }
      },
    },
  },

  methods: {
    CurrencyFormatLong,

    selectItem() {
      this.selected = !this.selected;

      if (this.selected) {
        this.$emit('select', this.rateSheet);
      } else {
        this.$emit('select', {});
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/variables/font-variables";
@import "@/assets/scss/vuetify/elevation.scss";

$padding: 1rem;

.rate-sheet-options-expandable-categories {
  .select-offer {
    margin: 1rem;
    color: var(--grayscale-color-1) !important;
    font-size: 0.875rem !important;
    border: 1px solid var(--grayscale-color-1) !important;
  }

  .select-offer-selected {
    border: 2px solid var(--grayscale-color-1) !important;
  }

  &__card {
    box-shadow: $v-elevation-4;
    margin-bottom: 2rem;
    display: grid;
    grid-template-rows: min-content min-content min-content;
    grid-template-areas: "header" "body" "footer";
    color: var(--grayscale-color-1);

    &__header {
      min-height: 2.625rem !important;
      background: var(--grayscale-color-5);
      border-radius: 4px;
      padding: 0.2rem 1rem;
      display: grid;
      grid-template-columns: max-content minmax(0, 1fr) max-content;
      grid-template-areas: "select title more";
      grid-area: header;
      align-items: center;

      &__title {
        text-align: center;
        grid-area: title;
        font-weight: 900;
        font-size: $font-size-standard;
        line-height: 150%;
        color: inherit;
      }

      &__more {
        grid-area: more;
      }
    }

    &__body {
      border-top: 1px solid var(--grayscale-color-4);
      border-bottom: 1px solid var(--grayscale-color-4);
      grid-area: body;
      padding: 0;

      &__header {
        font-size: .75rem;
        line-height: 150%;
        text-align: center;
        color: inherit;
      }

      &__cards {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        grid-gap: 1rem;
        margin-top: 0.5rem;
      }

      &--during,
      &--after {
        text-align: center;
        padding: 0.5rem 1rem 1rem;
        min-height: 6rem;
      }

      &--during {
        background-color: #ffffff;
      }

      &--after {
        background-color: var(--grayscale-color-5);
      }
    }

    &__footer {
      border: 1px solid var(--grayscale-color-4);
      text-align: center;
      grid-area: footer;
      padding: $padding;

      &__info,
      &__header {
        font-size: 0.875rem;
        line-height: 150%;
        color: inherit;
      }

      &__header {
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      &__info {
        font-weight: normal;
      }
    }
  }
}

.compare-panel {
  min-height: 3rem !important;

  &:before {
    box-shadow: none;
  }

  &__cont-text {
    font-size: 0.75rem;
    color: var(--grayscale-color-1);
    display: block;
    text-align: center;
    padding: 0.5rem 0;
  }
}

.standard-collapsable-panel {
  &__title {
    border-radius: 0 !important;
  }
  &__title--2 {
    font-size: 0.875rem !important;
    padding: 0.875rem 1rem !important;
    background-color: #ffffff !important;
  }
}

.compare-payments {
  font-size: 0.875rem;
  border-radius: 0;
  padding: 1rem 1.25rem;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}

:deep(.v-expansion-panel__shadow) {
  box-shadow: none;
}
</style>
