import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    elevation: "5",
    class: _normalizeClass(["bubble-card", _ctx.customClass]),
    ripple: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, {
        class: _normalizeClass(_ctx.computedTitleClass)
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "card-title", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["class"]),
      _createVNode(_component_v_card_text, { class: "bubble-card__content" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }),
      (_ctx.$slots['card-actions'])
        ? (_openBlock(), _createBlock(_component_v_card_actions, {
            key: 0,
            class: "bubble-card__actions"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "card-actions", {}, undefined, true)
            ]),
            _: 3
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class"]))
}